import React, {useState, useEffect} from 'react';
import './App.css';


const GOOGLE_MAPS_API_KEY = 'AIzaSyBbyiY5kGLq6ILypCswqifpGJKFgrCGMew';
const GOOGLE_MAP_ID = '84591267f7b3a201';
const GOOGLE_MAPS_API_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&v=beta&map_ids=${GOOGLE_MAP_ID}`;

const geo_data = {
    koeln_shape_url: 'https://geoportal.stadt-koeln.de/arcgis/rest/services/basiskarten/kgg/MapServer/5/query?f=geojson&where=objectid+is+not+null',
    kvb_hsbereiche: 'https://map.fkmps.de/public/kvb_hsbereiche.json',
    kvb_hs: 'https://map.fkmps.de/public/kvb_hs.json'
}

let map, google, tween;

const App = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = GOOGLE_MAPS_API_URL;
        const head = document.querySelector('head');
        head.appendChild(script);
        script.onload = () => {
            map = new window.google.maps.Map(document.getElementById('map'),{
                center: {lat: 50.937, lng: 6.958},
                heading: 0,
                tilt: 0,
                zoom: 11,
                keyboardShortcuts: false,
                clickableIcons: false,
                disableDefaultUI: true,
                // mapId: GOOGLE_MAP_ID,
                gestureHandling: "greedy",
                fullscreenControl: true,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    mapTypeIds: ["roadmap","satellite"]
                }
            })
            map.addListener('mousedown', evt => {
                if(tween){
                    tween.stop()
                    tween = null
                }
            });
            // map.data.loadGeoJson(
            //     geo_data.koeln_shape_url
            // )
            // map.data.setStyle({
            //     strokeColor: 'green',
            //     strokeOpacity: 1.0,
            //     fillOpacity: 0
            // });
            map.data.loadGeoJson(
                geo_data.kvb_hs
            )
        }
    })


    return (
        <div className="App">
            <div id="map"></div>
            <div className="info"></div>
        </div>
    );
}

export default App;
